import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from "react"
import PrivateRoute from "../components/privateRoute"
import Layout from "../components/layout"
import { Router } from "@reach/router"


class App extends Component {

  componentDidMount() {
    console.log('Triggered : App')
  }

  render(){
    return(
      <Layout>
        <Router>
          <PrivateRoute path="/api/:page" />

        </Router>
      </Layout>
    )
  }

}


export default App;
